import {Link, usePage} from '@inertiajs/inertia-react';
import {
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiArrowExpandHorizontal,
  mdiBriefcaseAccount,
  mdiCalculator,
  mdiCalendar,
  mdiCalendarMonth,
  mdiCardText,
  mdiChartBar,
  mdiChartTree,
  mdiCheckDecagram,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCoatRack,
  mdiCog,
  mdiFileDocument,
  mdiFileDocumentMultiple,
  mdiFileDocumentOutline,
  mdiFolderMarker,
  mdiFolderOutline,
  mdiGauge,
  mdiHome,
  mdiHook,
  mdiJumpRope,
  mdiLink,
  mdiMapMarker,
  mdiRuler,
  mdiRulerSquare,
  mdiSpeedometer,
  mdiTable,
  mdiTag,
  mdiTextureBox,
  mdiTruck,
  mdiTruckOutline,
  mdiWater,
} from '@mdi/js';
import {Icon} from '@mdi/react';
import LogoImage from 'Images/logo_light.png';
import LogoImageCollapsed from 'Images/logo_light_initials.png';
import LeftNavItem from 'Layouts/Partials/LeftNavItem';
import Menu, {MenuItem, SubMenu} from 'rc-menu';
import React, {useEffect, useState} from 'react';
import cls from 'Support/cls';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';
import TutorialSwitch from './TutorialSwitch';

const isActive = (...names) => names.some(name => route().current(name));

const LeftNav = ({mobileOpen, setMobileOpen}) => {
  const user = useUser();
  const {application: {site_name, demo_mode}} = usePage().props;

  const [collapsed, setCollapsed] = useState(false);
  const [collapse, setCollapse] = useState(collapsed);

  const minItemsToCollapse = 1; // minimum items needed for left nav to collapse

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => setCollapse(collapsed && !mobileOpen), [collapsed, mobileOpen]);

  const recursiveFilter = (acc, item) => {
    if (!(typeof item.filter !== 'function' || item.filter())) return acc;
    if (Array.isArray(item?.subMenuItems)) {
      item.subMenuItems = item.subMenuItems.reduce(recursiveFilter, []);
    }
    acc.push(item);
    return acc;
  };

  let items = [
    {
      title: 'Home',
      href: route('home'),
      icon: mdiHome,
      active: isActive('home'),
    },
    {
      title: 'Dashboards',
      href: route('dashboard'),
      icon: mdiSpeedometer,
      filter: () => ['admin', 'developer', 'tracking_management_user', 'user'].includes(user.role.value),
      subMenuItems: [
        {
          title: 'Main Dashboard',
          href: route('dashboard'),
          icon: mdiHome,
          active: isActive('dashboard'),
          filter: () => ['admin', 'developer', 'tracking_management_user', 'user'].includes(user.role.value),
        },
        {
          icon: mdiGauge,
          title: 'Management Dashboard',
          href: route('info-board.management', {auth_token: user.auth_token}),
        },
        {
          icon: mdiGauge,
          title: 'Regional Dashboard',
          href: route('info-board.management-region', {auth_token: user.auth_token}),
        },
        {
          icon: mdiGauge,
          title: 'Dipping Dashboard',
          href: route('info-board.dipping', {auth_token: user.auth_token}),
        },
        {
          title: 'Hot Dipping',
          href: route('dips.index'),
          icon: mdiWater,
          filter: () => ['admin', 'developer', 'tracking_management_user', 'dipping_user', 'user'].includes(user.role.value),
          active: isActive('dips', 'dips.*'),
        },
      ],
    },
    {
      title: 'Reporting',
      icon: mdiChartBar,
      filter: () => ['admin', 'developer', 'tracking_management_user'].includes(user.role.value),
      subMenuItems: [
        {
          title: 'Monthly Metrics',
          icon: mdiCalendarMonth,
          href: route('reports.monthly-metrics'),
          isActive: isActive('reports.monthly-metrics'),
          filter: () => ['admin', 'developer', 'tracking_management_user'].includes(user.role.value),
        },
        {
          title: 'Customer Metrics',
          icon: mdiCalendarMonth,
          href: route('reports.customer-metrics'),
          isActive: isActive('reports.customer-metrics'),
          filter: () => ['admin', 'developer', 'tracking_management_user'].includes(user.role.value),
        },
      ],
    },
    {
      title: 'Client',
      icon: mdiAccountGroup,
      filter: () => ['admin', 'developer', 'tracking_management_user', 'user'].includes(user.role.value),
      subMenuItems: [
        {
          title: 'Clients',
          icon: mdiAccountGroup,
          href: route('clients.index'),
          active: isActive('clients', 'clients.*'),
        },
        {
          title: 'Customer Users',
          href: route('customer-users.index'),
          icon: mdiBriefcaseAccount,
          active: isActive('customer-users', 'customer-users.*'),
        },
        // {
        //   title: 'Information',
        //   href: route('customer-information.index'),
        //   icon: mdiLibrary,
        //   active: isActive('customer-information', 'customer-information.*'),
        // },
        {
          title: 'Articles',
          href: route('articles.index'),
          icon: mdiFileDocumentMultiple,
          active: isActive('articles', 'articles.*'),
        },
      ],
      active: isActive('clients', 'clients.*', 'customer-users', 'customer-users.*'),
    },
    {
      title: 'Driver Dashboard',
      href: route('driver.index'),
      icon: mdiSpeedometer,
      active: isActive('driver.*'),
      filter: () => ['driver_user'].includes(user.role.value),
    },
    {
      title: 'Pickup Lists',
      href: route('pickups.index'),
      icon: mdiTruckOutline,
      active: isActive('pickups', 'pickups.*'),
      filter: () => ['admin', 'developer', 'job_creation_user', 'tracking_management_user'].includes(user.role.value),
    },
    {
      title: 'Jobs',
      href: route('jobs.index'),
      icon: mdiFolderOutline,
      active: isActive('jobs', 'jobs.*'),
    },
    {
      title: 'Quotes',
      href: route('quotes.index'),
      icon: mdiFileDocumentOutline,
      active: isActive('quotes', 'quotes.*'),
      filter: () => ['admin', 'developer', 'job_creation_user', 'tracking_management_user'].includes(user.role.value),
    },
    {
      title: 'My Pickups',
      href: route('pickups.show'),
      icon: mdiTruckOutline,
      active: isActive('pickups.show'),
      filter: () => ['driver_user'].includes(user.role.value),
    },
    {
      title: 'Tracking Management',
      icon: mdiFolderMarker,
      filter: () => ['admin', 'developer', 'tracking_management_user'].includes(user.role.value),
      subMenuItems: [
        {
          title: 'Processing Schedule',
          icon: mdiCalendar,
          href: route('tracking-management.index'),
          active: isActive('tracking-management', 'tracking-management.*'),
        },
        {
          title: 'Processing Table',
          icon: mdiTable,
          href: route('loadup.index'),
          active: isActive('loadup', 'loadup.*'),
        },
      ],
    },
    {
      title: 'Load Up',
      href: route('loadup.index'),
      icon: mdiHook,
      filter: () => ['load_up_user'].includes(user.role.value),
      active: isActive('loadup', 'loadup.*'),
    },
    {
      title: 'Hot Dipping',
      href: route('dips.index'),
      icon: mdiWater,
      filter: () => ['dipping_user'].includes(user.role.value),
      active: isActive('dips', 'dips.*'),
    },
    {
      title: 'Dispatch',
      icon: mdiTruck,
      filter: () => ['admin', 'developer', 'tracking_management_user', 'dispatch_user', 'driver_user', 'user'].includes(user.role.value),
      subMenuItems: [
        {
          title: 'Visual Batch Assurance',
          href: route('dispatch.assure'),
          icon: mdiCheckDecagram,
          active: isActive('dispatch.assure'),
        },
        {
          title: 'Trucking Manifests',
          href: route('dispatch.index'),
          icon: mdiTruck,
          active: isActive('dispatch.index'),
        },
      ],
    },
    {
      title: 'Invoices',
      href: route('invoices.index'),
      icon: mdiFileDocumentMultiple,
      filter: () => ['admin', 'developer', 'tracking_management_user', 'user'].includes(user.role.value),
      active: isActive('invoices', 'invoices.*'),
    },
    {
      title: 'Quality Assurance',
      icon: mdiCheckDecagram,
      filter: () => ['admin', 'developer', 'tracking_management_user', 'qa_user', 'user'].includes(user.role.value),
      subMenuItems: [
        {
          title: 'QA Reports',
          href: route('qa.reports.index'),
          icon: mdiFileDocument,
          active: isActive('qa.reports', 'qa.reports.*'),
        },
        {
          title: 'Chemical Reports',
          icon: mdiFileDocumentMultiple,
          href: route('reports.chemicals'),
          isActive: isActive('reports.chemicals'),
        },
        {
          title: 'Test Equipment',
          href: route('qa.test-equipment.index'),
          icon: mdiRuler,
          active: isActive('qa.test-equipment', 'qa.test-equipment.*'),
        },
        {
          title: 'QA Standards',
          href: route('qa.standards.index'),
          icon: mdiFileDocument,
          active: isActive('qa.standards', 'qa.standards.*'),
        },
        {
          title: 'Article Ranges',
          href: route('qa.article-ranges.index'),
          icon: mdiArrowExpandHorizontal,
          active: isActive('qa.article-ranges', 'qa.article-ranges.*'),
        },
        {
          title: 'Surface Areas',
          href: route('qa.surface-area-tests.index'),
          icon: mdiRulerSquare,
          active: isActive('qa.surface-area-tests', 'qa.surface-area-tests.*'),
        },
        {
          title: 'Steel/Coating Thickness',
          href: route('qa.steel-coating-thickness.index'),
          icon: mdiTextureBox,
          active: isActive('qa.steel-coating-thickness', 'qa.steel-coating-thickness.*'),
        },
        {
          title: 'Surface Descriptions',
          href: route('qa.common-surface-descriptions.index'),
          icon: mdiCardText,
          active: isActive('qa.common-surface-descriptions', 'qa.common-surface-descriptions.*'),
        },
      ],
    },
    {
      title: 'Users',
      href: route('users.index'),
      icon: mdiAccountMultiple,
      filter: () => demo_mode ?
        ['developer'].includes(user.role.value)
        :
        ['admin', 'developer', 'tracking_management_user', 'user'].includes(user.role.value),
      active: isActive('users', 'users.*'),
    },
    {
      title: 'Resources',
      icon: mdiLink,
      subMenuItems: [
        {
          icon: mdiChartTree,
          title: 'Process Flowchart',
          href: route('flow-chart.index'),
        },
        {
          icon: mdiCalculator,
          title: '\'Will it fit?\' Calculator',
          href: route('calculator.will-it-fit'),
        },
        {
          icon: mdiCalculator,
          title: 'Hole Sizing Calculator',
          href: route('calculator.hole-sizing'),
        },
        {
          icon: mdiAccountGroup,
          title: 'Customer Portal',
          href: route('customer.login'),
        },
      ],
    },
    {
      title: 'Settings', icon: mdiCog,
      filter: () => ['admin', 'developer', 'job_creation_user', 'tracking_management_user', 'user'].includes(user.role.value),
      subMenuItems: [
        // {
        //   title: 'Default Values',
        //   href: route('settings.default-values'),
        //   icon: mdiFormTextbox,
        //   active: isActive('settings.default-values'),
        //   filter: () => !['job_creation_user'].includes(user.role.value),
        // },
        {
          title: 'Towns',
          href: route('areas.index'),
          icon: mdiMapMarker,
          active: isActive('areas.*'),
          filter: () => !['job_creation_user'].includes(user.role.value),
        },
        {
          title: 'Provinces',
          href: route('zones.index'),
          icon: mdiMapMarker,
          active: isActive('zones.*'),
          filter: () => !['job_creation_user'].includes(user.role.value),
        },
        {
          title: 'Pricing',
          href: route('pricing.edit'),
          icon: mdiCalculator,
          active: isActive('pricing.*'),
          filter: () => ['admin', 'developer'].includes(user.role.value),
        },
        {
          title: 'Quoting Terms',
          href: route('quoting-terms.edit'),
          icon: mdiCalculator,
          active: isActive('quoting-terms.*'),
          filter: () => ['admin', 'developer'].includes(user.role.value),
        },
        {
          title: 'Client Types',
          href: route('client-types.index'),
          icon: mdiAccountGroup,
          active: isActive('client-types.*'),
          filter: () => !['job_creation_user'].includes(user.role.value),
        },
        {
          title: 'Statuses',
          href: route('job-statuses.index'),
          icon: mdiTag,
          active: isActive('job-statuses.*'),
          filter: () => !['job_creation_user'].includes(user.role.value),
        },
        {
          title: 'Batch Statuses',
          href: route('job-batch-statuses.index'),
          icon: mdiTag,
          active: isActive('job-batch-statuses.*'),
          filter: () => !['job_creation_user'].includes(user.role.value),
        },
        {
          title: 'Common Items',
          href: route('common-items.index'),
          icon: mdiTag,
          active: isActive('common-items.*'),
        },
        {
          title: 'Trucks',
          href: route('trucks.index'),
          icon: mdiTruckOutline,
          active: isActive('trucks.*'),
          filter: () => !['job_creation_user'].includes(user.role.value),
        },
        {
          title: 'Jigs',
          href: route('jigs.index'),
          icon: mdiCoatRack,
          active: isActive('jigs.*'),
        },
        {
          title: 'Ropes',
          href: route('ropes.index'),
          icon: mdiJumpRope,
          active: isActive('ropes.*'),
        },
        {
          title: 'Ticker Messages',
          href: route('ticker-messages.index'),
          icon: mdiFileDocument,
          active: isActive('ticker-messages.*'),
          filter: () => !['job_creation_user'].includes(user.role.value),
        },
      ],
    },
  ].reduce(recursiveFilter, []);

  if (user.is_dips_user) {
    items = [
      {title: 'Dips', href: route('dips.index'), icon: mdiWater, active: isActive('dips', 'dips.*')},
    ];
  }

  const onClick = () => {
    setMobileOpen(false);
  };


  if (items.length < minItemsToCollapse) { //if items in side bar is less than minToCollapse
    if (!collapsed) {
      setCollapsed(true);
    }
  }

  return (
    <div className={cls(mobileOpen ? 'absolute inset-0 z-40 flex' : 'hidden lg:flex', 'lg:flex-shrink-0')}
         id="navigation">
      <div className={cls(mobileOpen ? 'fixed' : 'hidden', 'bg-gray-600 opacity-75 inset-0')}/>

      <div className={cls('flex flex-col z-50', collapse ? 'w-20' : 'w-64')}>
        <div className="flex flex-col h-0 flex-1 relative">
          <div className={cls(mobileOpen ? 'absolute' : 'hidden', 'top-0 right-0 p-1 -mr-14')}>
            <button
              onClick={onClick}
              id="mobile-nav-close"
              className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
              aria-label="Close sidebar">
              <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>

          <Link href={route('dashboard')}
                className={cls('flex items-center h-16 flex-shrink-0 bg-gray-900', collapsed ? 'px-2' : 'px-4')}>
            <img className="w-auto" src={collapsed ? LogoImageCollapsed : LogoImage} alt={site_name}/>
          </Link>

          <div
            className={cls(mobileOpen ? '' : 'scrollbar-overlay overflow-overlay', 'flex-1 flex flex-col')}>
            <nav className="flex-1 px-2 py-4 bg-gray-800">
              <button onClick={toggleSidebar} className={mobileOpen ? 'hidden' : 'display-inline'}>
                <Icon path={collapsed ? mdiChevronRight : mdiChevronLeft} size={1} color="white"/>
              </button>

              <Menu
                onClick={onClick}
                mode="inline"
                className="focus:outline-none space-y-1"
              >
                {
                  items.map(props => {
                    if (props.subMenuItems?.length) {
                      props.subMenuItems = props.subMenuItems.filter(subMenu => !!subMenu);
                    }

                    if (props.subMenuItems?.length) {
                      const content = (
                        <>
                          <Icon path={props.icon} size={1} className="mr-2 text-gray-500"/>
                          <span className={cls(collapse ? 'hidden' : 'display-inline')}>
                            {props.title}
                          </span>
                        </>
                      );
                      return (
                        <SubMenu
                          key={props.title}
                          title={content}
                          className={cls(
                            'bg-gray-800 hover:bg-gray-700 text-gray-300 hover:text-white focus:text-white group text-sm leading-6',
                            'font-medium rounded-md focus:outline-none focus:bg-gray-700 transition ease-in-out duration-150 cursor-pointer select-none',
                            (collapsed ? 'absolute min-w-16' : ''),
                          )}
                        >
                          {props.subMenuItems.map(props => (
                            <MenuItem key={props.href}>
                              <LeftNavItem {...props} collapsed={false}/>
                            </MenuItem>
                          ))}
                        </SubMenu>
                      );
                    } else {
                      return (
                        <MenuItem key={props.href} className="padding-none">
                          <LeftNavItem {...props} collapsed={collapse}/>
                        </MenuItem>
                      );
                    }
                  })
                }
              </Menu>
            </nav>
          </div>
        </div>
        <TutorialSwitch small={collapsed}/>
      </div>
    </div>
  );
};

export default LeftNav;
